<template>
  <button
    type="button"
    class="vCloseButton"
    @click="handleClick"
  />
</template>

<script>
export default {
  name: 'VCloseButton',
  emits: ['handleClick'],
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>

<style lang="scss">
.vCloseButton {
  position: absolute;
  top: 24px;
  right: 16px;
  min-width: 24px;
  width: 24px;
  max-width: 24px;
  min-height: 24px;
  height: 24px;
  max-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: #fbfbfc;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  border: 1px solid #edeef1;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 2px;
    width: 12px;
    background: #c01718;
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 2px;
    width: 12px;
    background: #c01718;
  }
  &:hover {
    background: #c01718;
    &::after,
    &::before {
      background: #fff;
    }
  }
}
</style>
